@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  src: url('assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('assets/fonts/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: lighter;
  src: url('assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('assets/fonts/Inter-Regular.woff') format('woff');
}

body {
  font-family: 'Inter', 'sans-serif';
  background-color: #d9e1f7;
}

/* hide arrows insider input type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
